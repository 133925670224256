<template>
  <div class="page-container">
    <el-col>
      <el-card>
        <div slot="header" class="clearfix">
          <span>讲座报名</span>
        </div>

        <el-table :data="applicateList" border stripe>
          <el-table-column type="index" label="ID" width="50" />
          <el-table-column prop="floorNo" label="发布人" width="120" />
          <el-table-column prop="floorNo" label="主题" />
          <el-table-column prop="floorNo" label="讲座地点" />
          <el-table-column prop="floorNo" label="开始时间" />
          <el-table-column prop="floorNo" label="结束时间" />
          <el-table-column prop="floorNo" label="报名人数" width="120" />
          <el-table-column prop="remarks" label="发布状态" width="120" />
          <el-table-column label="操作" fixed="right">
            <template v-slot="scoped">
              <el-button
                type="primary"
                size="mini"
                @click="createTeachRoom('edit', scoped.row.id)"
                >讲座详情</el-button
              >
              <el-button
                type="warning"
                size="mini"
                @click="createTeachRoom('edit', scoped.row.id)"
                >通过</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deleteTeachRoom(scoped.row.id)"
                >驳回</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
  </div>
</template>

<script>
export default {
  data () {
    return {
      applicateList: [{}]
    }
  }
}
</script>

<style></style>
